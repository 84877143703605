html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Inter Regular';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sawton Circular';
    src: url('./assets/fonts/Sawton-Circular-Webfont/sawtoncircular-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    overflow: hidden;
}

body {
    overflow: auto;
}
